import colors from "@/assets/theme/base/colors";
import { grey } from "@mui/material/colors";
import { padding } from "@mui/system";

/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function navbar(theme, ownerState) {
  const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
  const { transparentNavbar, absolute, light } = ownerState;

  const { dark, white, text, transparent } = palette;
  const { navbarBoxShadow } = boxShadows;
  const { rgba, pxToRem } = functions;
  const { borderRadius } = borders;

  return {
    boxShadow: transparentNavbar || absolute ? "none" : navbarBoxShadow,
    backdropFilter: transparentNavbar || absolute ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
    // backgroundColor:
    //   transparentNavbar || absolute ? `${transparent.main} !important` : rgba(white.main, 0.8),
    backgroundColor : 'white',

    color: () => {
      let color;

      if (light) {
        color = white.main;
      } else if (transparentNavbar) {
        color = text.main;
      } else {
        color = dark.main;
      }

      return color;
    },
    //top: absolute ? 0 : pxToRem(12),
    minHeight: pxToRem(100), //header height
    display: "grid",
    alignItems: "center",
    //borderRadius: borderRadius.xl,
    paddingTop: pxToRem(8),
    paddingBottom: pxToRem(8),
    paddingRight: absolute ? pxToRem(8) : 0,
    paddingLeft: absolute ? pxToRem(16) : 0,

    "& > *": {
      transition: transitions.create("all", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& .MuiToolbar-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      [breakpoints.up("sm")]: {
        minHeight: "auto",
        padding: `${pxToRem(4)} ${pxToRem(16)}`,
      },
    },
  };
}

const navbarContainer = ({ breakpoints }) => ({
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  pt: 0.5,
  pb: 0.5,
  

  ".MuiIconButton-root:hover" : {
    translate : "scale(1.1)" , 
  },
  paddingRight:0,
  [breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const navbarRow = ({ breakpoints }, { isMini }) => ({
  color : colors.blue.header ,

  "svg" : {
    fontSize : '1.4em !important',
    color : grey[900]
  },

  [breakpoints.up("md")]: {
    justifyContent: isMini ? "space-between" : "stretch",
    width: isMini ? "100%" : "max-content",
  },

  [breakpoints.up("xl")]: {
    justifyContent: "stretch !important",
    width: "max-content !important",
  },
});

const breadcrumbStyle = ({ breakpoints }, { isMini }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection : 'column',
  justifyContent: "space-between",
  width: "100%",
  pb: 0,
  "svg" : {
    fontSize : '2.8em !important',
    color : grey[800],
  },

  ".breadcrump" : {
    "ol" : {
      "li:last-child" : {
        ".MuiTypography-root" : {
          fontWeight : 'bold',
          fontStyle : 'italic'
        }
      }
    },
    ".MuiTypography-root" : {
      fontWeight : 200
    }
   
  }
});

const navbarIconButton = ({ typography: { size }, breakpoints }) => ({
  px: 0.75,
  
  "& .material-icons, .material-icons-round": {
    fontSize: `4rem !important`,
    width:'3em'
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

const navbarDesktopMenu = ({ breakpoints }) => ({
  display: "none !important",
  cursor: "pointer",

  [breakpoints.up("xl")]: {
    display: "inline-block !important",
  },
});

const navbarMobileMenu = ({ breakpoints }) => ({
  display: "inline-block",
  lineHeight: 0,

  [breakpoints.up("xl")]: {
    display: "none",
  },
});

export {
  navbar,
  navbarContainer,
  navbarRow,
  breadcrumbStyle,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
};
