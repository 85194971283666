/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import AxiosService from "@/services/axios.service";
import Leaflet from "leaflet";
// The Soft UI Dashboard PRO React main context
const SoftUI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = "SoftUIContext";
import DashboardNavbar from "@/examples/Navbars/DashboardNavbar"

// Soft UI Dashboard PRO React reducer
function reducer(state, action) {

  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "FAVICON": {
      return { ...state, favicon: action.value };
    }
    case "LOGO": {
      return { ...state, logo: action.value };
    }
    case "DRAWER": {
      return { ...state, open: action.value };
    }
    case "EVENT": {
      return { ...state, event: action.value };
    }
    case "SEARCH": {
      return { ...state, search: action.value };
    }
    case "MAPICON": {
      return { ...state, mapicon: action.value}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Soft UI Dashboard PRO React context provider
function SoftUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    favicon: "",
    search: "",
    open: false,
    event: [],
    logo: "",
    mapicon:new Leaflet.Icon({
      iconUrl: "../../../../../vendor/core/core/base/images/map/marker-icon.png",
    
    }),

  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let response = await AxiosService.get('/head-data');
      
      dispatch({ type: "LOGO", value: response?.data?.logo })
      dispatch({ type: "FAVICON", value: response?.data?.favicon })
    }

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])




  return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>;
}

// Soft UI Dashboard PRO React custom hook for using context
function useSoftUIController() {
  const context = useContext(SoftUI);

  if (!context) {
    throw new Error("useSoftUIController should be used inside the SoftUIControllerProvider.");
  }

  return context;
}

// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setFavicon = (dispatch, value) => dispatch({ type: "FAVICON", value });
const setSearch = (dispatch, value) => dispatch({ type: "SEARCH", value });
const setDrawer = (dispatch, value) => dispatch({ type: "DRAWER", value });
const setEvent = (dispatch, value) => dispatch({ type: "EVENT", value });
const setLogo = (dispatch, value) => dispatch({ type: "LOGO", value });
const setMapicon = (dispatch, value) => dispatch({ type: "MAPICON", value });


export {
  SoftUIControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setFavicon,
  setSearch,
  setDrawer,
  setEvent,
  setLogo,
  setMapicon
};
