import './bootstrap';
//import '../css/app.css';

import { createRoot } from 'react-dom/client';

import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import { BrowserRouter } from "react-router-dom";
// Soft UI Context Provider
import { SoftUIControllerProvider } from "./context";
//import Appp from "./Appp";
import { ThemeProvider } from "@mui/material/styles";
import theme from "@/assets/theme/mui-theme";
import CssBaseline from "@mui/material/CssBaseline";
import DashboardLayout from "@/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@/examples/Navbars/DashboardNavbar";
import useWindowSize from './hooks/useWindowSize';
import { usePage } from '@inertiajs/react'
import Footer from './examples/Footer';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'ΚΟΙΝΩΝΙΚΗ ΠΟΛΙΤΙΚΗ - ΔΗΜΟΣΙΑ ΥΠΗΡΕΣΙΑ ΑΠΑΣΧΟΛΗΣΗΣ';

createInertiaApp({
    title: (title) => `${title}`,
    resolve: name => {
        //console.log(usePage().props)

        const pages = import.meta.glob('./Pages/**/*.tsx')
        return pages[`./Pages/${name}.tsx`]()
    },
    setup({ el, App, props }) {

       
        const root = createRoot(el);
        const authUser = props?.initialPage?.props?.auth;
        const headerLogo = props?.initialPage?.props.headerLogo
        const socialLinks = props?.initialPage?.props.social_links
        const fullname = props?.initialPage?.props.fullname
    
        //const links = props.initialPage.props.links ? props.initialPage.props.links : [];
        //const crumbTitle = props.initialPage.props.crumbTitle ? props.initialPage.props.crumbTitle : "";


        root.render(
            <BrowserRouter>
                <SoftUIControllerProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />

                        <DashboardLayout headerLogo={headerLogo} socialLinks={socialLinks} auth={authUser} fullname={fullname}>
                            {/* <DashboardNavbar auth={authUser} links={links} crumbTitle={crumbTitle}/> */}
                            <DashboardNavbar auth={authUser} headerLogo={headerLogo} socialLinks={socialLinks} fullname={fullname}/>
                            <App {...props} />

                        </DashboardLayout>
                        <Footer />
                    </ThemeProvider>
                </SoftUIControllerProvider>
            </BrowserRouter>
        );

    },
    progress: {
        color: '#4B5563',
    },
});
