/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Breadcrumbs from '@mui/material/Breadcrumbs';
// Soft UI Dashboard PRO React components
import SoftBox from "@/components/SoftBox";
import SoftTypography from "@/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "@/assets/theme/base/typography";
import colors from "@/assets/theme/base/colors";
import { Divider } from "@mui/material";
import pxToRem from "@/assets/theme/functions/pxToRem";


function Footer({ company, links }) {
  const { size } = typography;

  const renderLinks = () => {
    return (
      <div role="presentation" >
        <Breadcrumbs separator="/" aria-label="breadcrumb">
          {links.map((link) => (

            <Link underline='hover' key={link.name} href={link.href} target="_blank">
              <SoftTypography variant="button" sx={{ fontWeight: 100 }}>
                &nbsp;{link.name}
              </SoftTypography>
            </Link>

          ))}

        </Breadcrumbs>
      </div>
    )
  }

  return (
    <SoftBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column" }}
      justifyContent="center"
      alignItems="center"
      p={1.5}
      className='footer-wrapper'
      position='relative'

      gap={2}
    >
      <SoftBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          fontSize: size.xs,
          mb: 0,
          p: 0,

          width: '100%',

          color: colors.blue.dark,
          fontWeight: 600,
          [breakpoints.up("lg")]: {
            mt: 0,
            width: '50%',
          },
          [breakpoints.down("md")]: {
            fontSize: '10px',
          },
        })}
      >
        © Δημόσια Υπηρεσία Απασχόλησης |&nbsp; <a target="_blank" sx={{ color: 'inherit!important' }} href="https://www.dypa.gov.gr/oroi-xrisis-prosopika-dedomena-kanones-asfalias-diadiktiakis-pylis"> Όροι Χρήσης</a> &nbsp;|&nbsp; <a target="_blank" sx={{ color: 'inherit!important' }} href="https://www.dypa.gov.gr/politiki-cookies">Πολιτική cookies</a>&nbsp; |

      </SoftBox>

      <SoftBox display='flex'>
        <Link fontSize='inherit' target="_blank" href="https://lioncode.gr/">

          <SoftTypography sx={({ breakpoints }) => ({
            fontWeight: 700,
            fontSize: typography.size.xs,
            color: colors.blue.dark,
            [breakpoints.down("md")]: {
              fontSize: typography.size.xxs,
            }
          })}>Κατασκευή Ιστοσελίδων&nbsp;&nbsp;</SoftTypography>
        </Link>
        <SoftTypography sx={({ breakpoints }) => ({
          color: colors.blue.header,
          fontSize: typography.size.xs,
          [breakpoints.down("md")]: {

          fontSize: typography.size.xxs,
          }
        })} fontWeight='bold'>Lioncode - Your IT People</SoftTypography>


    </SoftBox>


    </SoftBox >
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {

  links: [
    { href: "#", name: "Όροι Χρήσεις" },
    { href: "#", name: "Πολιτική Απορρήτου" },
    { href: "#", name: "Cookies" },

  ],
};

// Typechecking props for the Footer
Footer.propTypes = {

  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
