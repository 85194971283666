import styled from "@emotion/styled";
import SoftInput from "@/components/SoftInput";
import colors from "@/assets/theme/base/colors";

const StyledSoftInputSearch = styled(SoftInput)`
    
    input{
       color : ${colors.text.darkgreen};
       font-weight : normal;
       
    }

`

export default StyledSoftInputSearch