import Sidenav from "@/examples/Sidenav";
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "@/context";
import React, { useState, useEffect, useMemo } from "react";

// Soft UI Dashboard PRO React routes
import routes from "@/routes";
// Images
import brand from "@/assets/images/agroq.png";
import Success from "../pages/sweet-alerts/components/Success";
import { usePage } from '@inertiajs/react'

export default function SidebarLayout({ user, header, children, headerLogo = null, socialLinks=[], auth=null, fullname=null }) {

  //console.log(usePage().props)
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);


  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <Sidenav
      color={'success'}
      brand={brand}
      headerLogo={headerLogo}
      auth={auth}
      socialLinks={socialLinks}
      fullname={fullname}
      brandName="koinonikos tourismos"
      routes={routes}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}

    />
  )
}