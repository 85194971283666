import colors from "@/assets/theme/base/colors";

/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/



function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active, transparentSidenav , reverse } = ownerState;
  
  const { dark, white, text, transparent } = palette;
  const { xxl } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    //background: active && transparentSidenav ? white.main : transparent.main,
    background: colors.white.main,
    //color: active ? dark.main : text.main,
    color: '#000000',
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(8)} ${pxToRem(12.8)} ${pxToRem(8)} ${pxToRem(0)}`,
    paddingLeft : reverse && pxToRem(12.8),
    margin: `${pxToRem(8)} ${pxToRem(16)}`,
    borderRadius: borderRadius.xxl,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: boxShadows.custom,
    //boxShadow: active && transparentSidenav ? xxl : "none",
    // [breakpoints.up("xl")]: {
    //   boxShadow: () => {
    //     if (active) {
    //       return transparentSidenav ? xxl : "none";
    //     }

    //     return "none";
    //   },
    //   transition: transitions.create("box-shadow", {
    //     easing: transitions.easing.easeInOut,
    //     duration: transitions.duration.shorter,
    //   }),
    // },
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active, transparentSidenav, sidenavColor } = ownerState;
  const { white, info, light, gradients } = palette;
  const { md } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    // background: () => {
    //   if (active) {
    //     return sidenavColor === "default" ? info.main : palette[sidenavColor].main;
    //   }

    //   return light.main;
    // },
    minWidth: pxToRem(0),//was 32,
    minHeight: pxToRem(32),
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    //boxShadow: md,
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    // [breakpoints.up("xl")]: {
    //   background: () => {
    //     let background;

    //     if (!active) {
    //       background = transparentSidenav ? white.main : light.main;
    //     } else if (sidenavColor === "default") {
    //       background = info.main;
    //     } else if (sidenavColor === "warning") {
    //       background = gradients.warning.main;
    //     } else {
    //       background = palette[sidenavColor].main;
    //     }

    //     return background;
    //   },
    // },

    // "& svg, svg g": {
    //   fill: active ? white.main : gradients.dark.state,
    // },

    "& svg, svg g": {
      fill: 'grey',
      width: '20px',
      height: '20px'
    },
  };
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: active ? white.main : gradients.dark.state,
});

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, transparentSidenav, active } = ownerState;

  const { size, fontWeightMedium, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(12.8),
    color: '#000000',
    [breakpoints.up("xl")]: {

      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.md,
      lineHeight: 0,
      color: '#000000',
    },
  };
}

function collapseArrow(theme, ownerState) {
  const { palette, typography, transitions, breakpoints, functions, colors } = theme;
  const { noCollapse, transparentSidenav, miniSidenav, open, reverse } = ownerState;
  const { dark, gradients, white } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.xl} !important`,
    fontWeight: 'bold',
    marginBottom: pxToRem(-1),
    transform: !reverse ? "rotate(-270deg)" : "rotate(-90deg)",
    //color: open ? dark.main : rgba(gradients.dark.state, 0.4),
    color: '#ffffff',
    background: '#0E4C88',
    //backgroundColor : 'transparent linear-gradient(134deg, #21FDD0 0%, #333394 100%) 0% 0% no-repeat padding-box',
    borderRadius: 180,
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
    ':hover': {
      transform: 'scale(1.1)'
    },

    [breakpoints.up("xl")]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? "none !important"
          : "block !important",
    },
  };
}

export { collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow };
