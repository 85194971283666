/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Base Styles
import colors from "./colors";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
    color: colors.black.main,
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${colors.black.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  "img": {
    width: '100%',
    borderRadius: 'inherit'
  },

  ".swiper-pagination": {
    position: 'relative',
    top: 10
  },

  ".condition-badge": {
    position: 'absolute',
    bottom: '10%',
    left: '10%',
    background: 'white',
  },


  "td:first-child , th:first-child": { borderRadius: '10px 0 0 10px' },
  "td:last-child, th:last-child": { borderRadius: '0 10px 10px 0' },

  "tr:nth-child(2n)": {
    background: colors.table.second,

  },
  "tr:nth-child(2n+1)": {
    background: colors.table.first,
  },

  "thead > tr": {
    background: 'inherit!important',

  },

  "table": {
    borderCollapse: 'separate!important',
    borderSpacing: ' 0 15px !important'
  },

  "th": {
    pt: 5,

  },

  ".MuiTypography-root": {
    verticalAlign: 'middle!important'
  },

  ".material-icons-outlined": {
    fontSize: 30
  },

  '::-webkit-scrollbar': {
    width: '5px' , 
    height: '5px' 
  },

  /* Track */
  '::-webkit-scrollbar-track': {
    width : '5px'
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: colors.gradients.custom,
    borderRadius: '10px'
  },

  ".link-dypa": {
    position: 'absolute',
    bottom: '5.3%',
    width: '100%',

    "@media only screen and (max-height : 670px)": {
      position: 'fixed',
      bottom: '0',               
    }
  },

  ".footer-wrapper": {

    "a": {
      color: colors.blue.dark,
    }
  },

  ".drawer" : {
    zIndex : '1300!important'
  }
  

};

export default globals;
